<template>
    <div class="app-content app-container">
        <!-- 搜索 -->
        <div style="margin-bottom: 20px">
            <el-form
                :inline="true"
                :model="formInline"
                class="demo-form-inline"
                ref="formInline"
                label-width="100px"
                size="medium"
                @submit.native.prevent
            >
                <el-form-item label="问卷ID" prop="wjdoId">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.wjdoId"
                        placeholder="问卷ID"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="问题" prop="wjqName">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.wjqName"
                        placeholder="问题"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="答案" prop="wjqValue">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.wjqValue"
                        placeholder="答案"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Uid" prop="uid">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.uid"
                        placeholder="Uid"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="Uscc" prop="uscc">
                    <el-input
                        style="width: 300px"
                        v-model="formInline.uscc"
                        placeholder="Uscc"
                        clearable
                        @keyup.enter.native="onSubmit"
                    ></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="onSubmit">搜索</el-button>
                    <el-button @click="resetForm('formInline')">重置</el-button>
                </el-form-item>
            </el-form>
            <!-- <div>
                <el-button type="danger" plain @click="allDeleteClick"
                    >删除</el-button
                >
            </div> -->
        </div>
        <!-- 表格 -->
        <div>
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>

                <el-table-column prop="id" label="#"> </el-table-column>
                <el-table-column
                    prop="wjdopId"
                    label="填报ID"
                     
                >
                </el-table-column>
                <el-table-column
                    prop="wjdoId"
                    label="问卷ID"
                     
                >
                </el-table-column>
                <el-table-column
                    prop="wjqName"
                    label="问题"
                     
                >
                </el-table-column>
                <el-table-column
                    prop="wjqValue"
                    label="答案"
                     
                >
                </el-table-column>
                <el-table-column
                    prop="wjqValueFull"
                    label="答案2"
                     
                >
                </el-table-column>
                <el-table-column prop="uid" label="uid"  >
                </el-table-column>
                <el-table-column prop="cid" label="cid"  >
                </el-table-column>
                <el-table-column prop="uscc" label="uscc"  >
                </el-table-column>
                <el-table-column
                    prop="time"
                    label="填写时间"
                     
                >
                    <template slot-scope="scope">{{
                        scope.row.time | dayjs
                    }}</template>
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="有效"
                     
                >
                    <template slot-scope="scope">{{
                        scope.row.doPost.status | effective
                    }}</template>
                </el-table-column>
                <!-- <el-table-column label="操作" fixed="right" width="50">
                    <template slot-scope="scope">
                        <el-button type="text" @click="editClick(scope.row)"
                            >编辑</el-button
                        >
                    </template>
                </el-table-column> -->
            </el-table>
            <!-- 分页 -->
            <div>
                <Pagination
                    v-bind:child-msg="pageparm"
                    @callFather="callFather"
                ></Pagination>
            </div>
        </div>
        <!-- 弹窗 -->
        <el-dialog title="信息" :visible.sync="dialogVisible" width="30%">
            <div>
                <el-form label-width="80px" :model="formLabelAlign">
                    <el-form-item label="UID">
                        <el-input
                            v-model="formLabelAlign.uid"
                            disabled
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="CID">
                        <el-input
                            v-model="formLabelAlign.cid"
                            disabled
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="UICC">
                        <el-input
                            v-model="formLabelAlign.uicc"
                            disabled
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="问题">
                        <el-input
                            v-model="formLabelAlign.wjqName"
                            disabled
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="答案">
                        <el-input v-model="formLabelAlign.wjqValue"></el-input>
                    </el-form-item>
                    <el-form-item label="答案2">
                        <el-input
                            v-model="formLabelAlign.wjqValueFull"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editSubmitClick"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import {
    answerListApi,
    answerUpdateApi,
    answerDeleteApi,
} from '@/api/survey.js'
export default {
    // 注册组件
    components: {
        Pagination,
    },
    data() {
        return {
            dialogVisible: false,
            formInline: {},
            tableData: [],
            multipleSelection: [],
            pageparm: {
                currentPage: 1,
                pageSize: 10,
                total: 20,
            },
            formLabelAlign: {},
        }
    },
    created() {
        if (sessionStorage.getItem('formInline')) {
            this.formInline = JSON.parse(sessionStorage.getItem('formInline'))
        }
        this.getAnswerListFun()
    },
    methods: {
        // 获取答案列表
        async getAnswerListFun(currentPage, pageSize) {
            console.log('currentPage', currentPage)
            var start = currentPage ? currentPage * pageSize - pageSize : 0
            var params = {
                start: start,
                length: pageSize ? pageSize : 10,
                draw: 1,
                orderStr:'time desc'
            }
            var p = Object.assign(params, this.formInline)
            var res = await answerListApi(p)
            this.tableData = res.data
            this.pageparm.total = res.recordsTotal
            // console.log('tableData', this.tableData);
        },
        // 删除答案
        async allDeleteClick() {
            var ids = this.multipleSelection.map((item) => item.wjdoId)

            this.$alert(
                '这确定要删除共' +
                    this.multipleSelection.length +
                    '条数据吗？删除后将无法恢复',
                '删除提示',
                {
                    confirmButtonText: '确定',
                    callback: async (action) => {
                        if (action === 'confirm') {
                            var params = {
                                ids: ids,
                                menuType: 'delete',
                            }
                            var res = await answerDeleteApi(params)
                            if (res.code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功',
                                })
                                this.getAnswerListFun()
                            } else {
                                this.$message({
                                    type: 'error',
                                    message: '删除失败：' + res.message,
                                })
                            }
                        }
                    },
                }
            )
        },
        onSubmit() {
            sessionStorage.setItem(
                'formInline',
                JSON.stringify(this.formInline)
            )
            // console.log('onSubmit', this.formInline);
            this.getAnswerListFun()
        },
        resetForm(formInline) {
            sessionStorage.removeItem('formInline')
            this.formInline={
                name: '',
            }
            sessionStorage.removeItem('formInline')
            this.formInline={
                name: '',
            }
            this.$refs[formInline].resetFields()
            this.getAnswerListFun()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        // 分页插件事件
        callFather(parm) {
            this.getAnswerListFun(parm.currentPage, parm.pageSize)
        },
        // 编辑模板
        editClick(row) {
            this.dialogVisible = true
            this.formLabelAlign = row
        },
        // 编辑提交
        editSubmitClick() {
            this.answerUpdateFun()
        },
        async answerUpdateFun() {
            this.formLabelAlign.time = ''
            var res = await answerUpdateApi(this.formLabelAlign)
            if (res.code === 200) {
                this.$message({
                    message: '修改成功',
                    type: 'success',
                })
                this.dialogVisible = false
            } else {
                this.$message({
                    message: '修改失败',
                    type: 'error',
                })
            }
        },
    },
}
</script>
<style scoped>
.app-content {
    background-color: #fff;
    padding: 20px;
}
</style>
